#menuColumn {
  padding-bottom: 0;
}

#contentColumn {
  padding-top: 0;
}

#menuColumn, #contentColumn {
  max-width: 100%;
}

#topMenu {
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}